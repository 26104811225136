<template>
  <a class="trash" href="" v-on:click="quickDelete($event)">
<i class="fas fa-trash-alt"></i>
  </a>
</template>

<script>
import JQuery from 'jquery'
let $ = JQuery
export default {
    name: "DeleteButton",
    props: ['id'],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
      },
    methods: {
      quickDelete(e){
        e.preventDefault();
        this.errors = {};
        console.log('deleted'+this.id);
        $('#deleted-'+this.id).hide();
        this.$emit("deleted");
        axios.delete('/api/phrases/'+this.id,
            {
              headers: {
                'Content-type': 'application/json',
              }
            })
            .then(response => {
              if (response.status = 204)
              {
                this.flash("Record deleted", "warning",{'timeout':3000});
                //this.form = {};
              }
            })
            .catch(error => {
              if (error.response){
                this.flash("There were errors with your content:","warning");
                console.log(error.response.data.errors);
                this.errors = error.response.data.errors;
              }
            });
      }

    }
}
</script>
