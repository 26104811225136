<template>
<div class="search-box-element">
    <div class="row justify-content-center">
        <div id="custom-search-input" class="col-md-8 dark:bg-gray-900 dark:border-gray-700 dark:shadow-none">
            <div class="input-group col-md-12">
                <input type="text" class="bg-transparent form-control input-lg" placeholder="Search term" name="search" value="" v-model="phrase" @keyup.enter="search" @keyup.ctrl.enter="addNew">
                <span class="input-group-btn">
                    <button class="btn btn-info btn-lg" type="submit" v-on:click="search">
                        <i class="fas fa-search"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div v-if="results.length > 0 || add" class="col-md-8">
            <transition-group name="fade" tag='div'>
                <div class="search-results search-results-popup" key='result'>
                    <table class="table table-striped table-hover table-list">
                        <tr v-for="(result,index) in results" :key="result.id">
                            <td class="column-phrase">
                                <a :href="'/phrase/'+ result.id" v-text='result.attributes.phrase'></a>
                            </td>
                            <td class="column-meta icons">
                              <div class="indicator" v-bind:class=" {'meaning': result.attributes.meaning}"></div>
                              <div class="indicator" v-bind:class=" {'keywords': result.attributes.keywords}"></div>
                            </td>
                            <td class="column-trash">
                              <delete-button :id='result.id' v-on:deleted="deleteRow(index)"></delete-button>
                            </td>
                        </tr>
                        <tr v-if="add" key="add">
                          <td colspan="3">
                            <a class='btn btn-primary' v-on:click.prevent="addNew">Add as new</a>
                          </td>
                        </tr>
                    </table>
                </div>
            </transition-group>
        </div>

    </div>
</div>
</template>

<script>
import DeleteButton from './delete-button'
export default {
    name: 'search-box',
    components: {
      DeleteButton,
    },
    data() {
        return {
            phrase: null,
            results: [],
            add: false,
        };
    },

    watch: {
        phrase(after, before) {
            this.quickSearch();
        }
    },

    methods: {
        quickSearch() {
            if (this.phrase.length > 2) {
                axios.get('/api/phrases/search', {
                        params: {
                            search: this.phrase
                        }
                    })
                    .then(response => {
                        if (response.status != 200) {
                          this.results = [];
                          this.add = false;
                        } else {
                          if (response.data.data.length < 5) {
                              this.add = true;
                            } else {
                              this.add = false;
                            }
                            this.results = response.data.data;
                        }
                    })
                    .catch(error => {
                        this.results = [];
                    });
            } else {
                this.results = [];
                this.add = false;
            }

        },

        deleteRow(index){
          this.results.splice(index,1);
        },
        search(){
          window.location.href = '/search/?s='+this.phrase;
        },

        addNew(){
          window.location.href = '/add/?phrase='+this.phrase;
        },

    }
}
</script>
